<template>
    <div>
        <titulo-protocolo @cadastrar="abrirModal" />
        <tabela-protocolos
            :itens="protocolos"
            @visualizar="abrirModal"
        />
        <paginacao
            v-model="paginacao"
            :total="paginacao.total"
            @input="pegarProtocolos"
        />
        <modal-protocolo
            :dados="modal.dados"
            :exibir="modal.exibir"
            @fechar="fecharModal"
        />
    </div>
</template>
<script>
// Aux & Utils
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import JwtService from '@/common/utils/jwt';

// services
import ProtocoloService from '@/common/services/protocolo/protocolo.service.js';

//components
import TituloProtocolo from './components/TituloProtocolo';
import TabelaProtocolos from './components/TabelaProtocolos.vue';
import ModalProtocolo from './components/formulario-protocolo/ModalProtocolo.vue';
import Paginacao from '@/components/paginacao/Paginacao';

export default {
    components: {
        TituloProtocolo,
        TabelaProtocolos,
        ModalProtocolo,
        Paginacao,
    },
    data() {
        return {
            paginacao: {
                numeroDaPagina: 1,
                tamanhoDaPagina: 10,
                total: 0,
            },
            modal: {
                exibir: false,
                dados: {},
            },
            protocolos: [],
        };
    },
    mounted() {
        this.pegarProtocolos();
    },

    methods: {
        pegarProtocolos() {
            this.$store.dispatch(START_LOADING);
            ProtocoloService.obterTodosPaginado({
                ...this.paginacao,
                usuarioId: JwtService.buscarId(),
            })
                .then(({ data }) => {
                    this.protocolos = data.itens;
                    this.paginacao.total = data.paginacao.totalDeElementos;
                })
                .catch((err) => {
                    mensagem.showErrors(err);
                })
                .finally(() => {
                    this.$store.dispatch(STOP_LOADING);
                });
        },

        abrirModal(dados) {
            this.modal.exibir = true;
            this.modal.dados = dados;
        },

        fecharModal() {
            this.modal.exibir = false;
            this.modal.dados = {};
            this.pegarProtocolos();
        },
    },
};
</script>

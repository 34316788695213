<template>
    <div>
        <modal
            :titulo="$t('ACADEMICO.FORMULARIO_PROTOCOLO')"
            id="modalMotivoCancelamento"
            :exibir="exibir"
            @fechar="fecharModal"
            :ocultarRodape="true"
        >
            <formulario-protocolo :dados="dados" @fechar-modal="fecharModal" />
        </modal>
    </div>
</template>

<script>
// Models:

//Components:
import Modal from '@/components/modal/Modal.vue';
import FormularioProtocolo from '@/views/academico/protocolo/components/formulario-protocolo/FormularioProtocolo.vue';

export default {
    name: 'ModalMotivoCancelamento',
    components: {
        Modal,
        FormularioProtocolo,
    },
    props: {
        exibir: { type: Boolean, default: true },
        dados: { type: Object, default: null },
    },
    methods: {
        fecharModal() {
            this.$emit('fechar');
        },
    },
};
</script>

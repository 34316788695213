<template>
    <topbar
        :titulo="$t('ACADEMICO.PROTOCOLO')"
        :subtitulo="$t('ACADEMICO.PROTOCOLO_SOBRE')"
        :breadcrumbs="breadcrumbs"
    >
        <template #header>
            <div class="d-flex justify-content-between ml-md-4 gap-1">
                <div></div>
                <b-button variant="info" @click="cadastrar">{{
                    $t('GERAL.CADASTRAR')
                }}</b-button>
            </div>
        </template>
    </topbar>
</template>
<script>
// Utils & Aux:
// import rotas from '@/common/utils/rotas';
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';

// Components:

export default {
    components: { Topbar },
    data() {
        return {
            breadcrumbs: [
                { titulo: this.$t('ACADEMICO.ACADEMICO') },
                { titulo: this.$t('ACADEMICO.PROTOCOLO') },
            ],
        };
    },
    methods: {
        // FUNÇÕES CRUD:
        cadastrar() {
            this.$emit('cadastrar');
        },
    },
};
</script>

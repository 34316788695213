<template>
    <b-table
        :fields="tabela.fields"
        :items="itens"
        responsive="sm"
        :empty-text="$t('GERAL.NENHUM_REGISTRO')"
        head-variant="light"
        class="mt-3"
        selectable
        show-empty
        striped
        hover
    >
        <template #cell(resposta)="item">
            <span :class="getRespostaClass(item.value)">
                {{ item.value ? $t('GERAL.SIM') : $t('GERAL.NAO') }}
            </span>
        </template>
        <template #cell(acoes)="item">
            <b-button variant="primary" @click="editar(item.item)">
                {{ $t('GERAL.VISUALIZAR') }}
            </b-button>
        </template>
    </b-table>
</template>
<script>
import datas from '@/common/utils/datas';

export default {
    props: {
        itens: {
            type: Array,
            default: Array,
        },
    },
    data() {
        return {
            tabela: {
                fields: [
                    {
                        key: 'dataCriacao',
                        label: this.$t('ACADEMICO.DATA_CRIACAO'),
                        sortable: true,
                        formatter: (value) => {
                            return value ? datas.formatDateToShow(value) : '';
                        },
                    },
                    {
                        key: 'nomeAluno',
                        label: this.$t('ACADEMICO.ALUNO'),
                        sortable: true,
                    },
                    {
                        key: 'descricao',
                        label: this.$t('ACADEMICO.DESCRICAO'),
                        sortable: true,
                    },
                    {
                        key: 'resposta',
                        label: this.$t('GERAL.RESPONDIDO'),
                        sortable: true,
                    },
                    {
                        key: 'acoes',
                        label: this.$t('GERAL.ACOES'),
                    },
                ],
            },
        };
    },
    methods: {
        editar(item) {
            this.$emit('visualizar', item);
        },
        getRespostaClass(value) {
            return value ? 'text-success' : 'text-danger';
        },
    },
};
</script>

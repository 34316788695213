<template>
    <b-row class="justify-content-end">
        <b-col md="12" v-if="opcoes.alunosVinculados?.length > 0">
            <input-select
                ref="pessoa"
                :disabled="!!dados?.id"
                :options="opcoes.alunosVinculados"
                :label="$t('ACADEMICO.ALUNO')"
                v-model="form.matriculaId"
                required
            />
        </b-col>

        <b-col md="12">
            <input-text-area
                ref="descricao"
                :disabled="!!dados?.id"
                v-model="form.descricao"
                :label="$t('ACADEMICO.SOLICITACAO')"
                :placeholder="$t('ACADEMICO.SOLICITACAO_SOBRE')"
                required
            />
        </b-col>

        <b-col md="12" v-if="dados?.resposta">
            <input-text-area
                :disabled="!!form.resposta"
                v-model="form.resposta"
                :label="$t('ACADEMICO.RESPOSTA')"
                :placeholder="$t('ACADEMICO.RESPOSTA')"
            />
        </b-col>

        <b-col v-if="!dados?.id" md="12">
            <input
                type="file"
                @change="selecionarAnexo"
                accept="image/jpg"
                required="false"
            />
        </b-col>
        <b-col md="12" v-if="imagemAnexo">
            <img
                alt="anexo"
                :src="imagemAnexo"
                class="img-fluid cover"
                @click="downloadImagem"
            />
            <b-button
                variant="primary"
                class="w-100 mt-2"
                @click="downloadImagem"
            >
                {{ $t('GERAL.BAIXAR') }}
            </b-button>
        </b-col>

        <b-col class="text-right mt-2" md="auto" v-if="!dados?.id">
            <b-button variant="primary" class="w-100" @click="cadastrar">{{
                $t('ACADEMICO.ABRIR_PROTOCOLO')
            }}</b-button>
        </b-col>
    </b-row>
</template>
<script>
//Helpers & Aux
import JwtService from '@/common/utils/jwt';
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

// components
import { InputTextArea, InputSelect } from '@/components/inputs';

// services
import ProtocoloService from '@/common/services/protocolo/protocolo.service.js';

export default {
    props: {
        dados: {
            type: Object,
            default: Object,
        },
    },
    components: {
        InputTextArea,
        // InputText,
        // InputSelectSearch,
        InputSelect,
    },
    data() {
        return {
            opcoes: {
                alunosVinculados: [],
            },
            form: this.dados || {
                resposta: '',
                descricao: '',
                anexo: '',
                usuarioId: '',
                matriculaId: '',
            },
            imagemAnexo: '',
            imagemNome: '',
            downloadLink: '',
        };
    },
    mounted() {
        this.getAlunosVinculadosResponsavel();
        this.getAnexo();
    },
    methods: {
        // OBTER DADOS
        getAlunosVinculadosResponsavel() {
            this.opcoes.alunosVinculados =
                JwtService.buscarAlunosVinculados().reduce(
                    (alunosSemDuplicatas, aluno) => {
                        const existe = alunosSemDuplicatas.some(
                            (a) => a.value === aluno.matriculaId
                        );
                        if (!existe) {
                            alunosSemDuplicatas.push({
                                text: aluno.nome,
                                value: aluno.matriculaId,
                            });
                        }
                        return alunosSemDuplicatas;
                    },
                    []
                );
        },

        async getAnexo() {
            if (!this.dados?.anexo) return;
            try {
                this.$store.dispatch(START_LOADING);

                const { data } = await ProtocoloService.buscarAnexo(
                    this.dados.id
                );
                this.imagemAnexo = 'data:image/jpg;base64,' + data.documento;
                this.imagemNome = data.nomeArquivo;
            } catch (err) {
                mensagem.showErrors(err);
            } finally {
                this.$store.dispatch(STOP_LOADING);
            }
        },

        downloadImagem() {
            const link = document.createElement('a');
            link.href = this.imagemAnexo;
            link.download = 'anexo.png';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },

        // FUNÇÕES CRUD:
        async cadastrar() {
            if (!this.validarFormulario()) return;
            try {
                this.$store.dispatch(START_LOADING);
                const matriculaId = this.form.matriculaId
                    ? this.form.matriculaId
                    : JwtService.buscarMatricula();
                const usuarioId = JwtService.buscarId();

                const { data } = await ProtocoloService.criar({
                    ...this.form,
                    matriculaId,
                    usuarioId,
                });

                mensagem.sucesso(this.$t('GERAL.SUCCESSO'), data.mensagem);
                this.fecharModal();
            } catch (err) {
                mensagem.showErrors(err);
            } finally {
                this.$store.dispatch(STOP_LOADING);
            }
        },

        //FUNÇÕES AUX
        fecharModal() {
            this.$emit('fechar-modal');
        },

        selecionarAnexo(event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = () => {
                    const base64Data = reader.result.split(',')[1]; // Remover a parte "data:image/jpeg;base64,"
                    this.form.anexo = base64Data;
                };
                reader.readAsDataURL(file);
            }
        },

        validarFormulario() {
            return helpers.validarFormulario(this.$refs);
        },
    },
};
</script>
